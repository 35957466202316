import gql from 'graphql-tag';

const spacesDelete = gql`
  mutation spacesDelete($id: GUID!) {
    spacesDelete(id: $id) {
      id
    }
  }
`;

const spacesImportJson = gql`
  mutation spacesImportJson($data: JSONObject!, $id: GUID!) {
    spacesImportJson(data: $data, id: $id)
  }
`;

const spacesInitialize = gql`
  mutation spacesInitialize {
    spacesInitialize
  }
`;

const spacesUpdate = gql`
  mutation spacesUpdate($data: JSONObject, $id: GUID!) {
    spacesUpdate(data: $data, id: $id) {
      id
    }
  }
`;

export { spacesDelete, spacesImportJson, spacesInitialize, spacesUpdate };
