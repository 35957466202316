import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from '../../../styles/theme';

const ContentSC = styled.div`
  background: ${colors.light[300]};
  box-shadow: 0 0 60px 0 ${colors.light[600]};
`;

const Content: FC = ({ children }) => (
  <ContentSC className="p-6 m-6 rounded-lg">{children}</ContentSC>
);

export default Content;
