import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useMutation } from 'react-apollo';

import Content from '../../components/base/Content';
import Items from '../../components/generic/Items';
import {
  spacesDelete as deleteMutation,
  spacesInitialize as initializeMutation,
} from '../../objects/spaces/mutations';
import spacesQuery from '../../objects/spaces/queries/spaces.graphql';
import query from '../../utils/query';
import requiredAuth from '../../utils/requireAuth';

const Spaces = query(Items, spacesQuery);

const SpacesPage: FC = () => {
  const pathname = '/admin/spaces/';
  const [initializeMutationFunc] = useMutation(initializeMutation);
  const [deleteMutationFunc] = useMutation(deleteMutation);

  const handleCreateOnClick = async () => {
    const result = await initializeMutationFunc();

    if (!result || result.errors) {
      throw new Error(result?.errors?.map(error => error.message).join(','));
    }

    navigate(`${pathname}update/${result.data.spacesInitialize}/`);
  };

  const handleDeleteFunc = async (id: string) =>
    deleteMutationFunc({
      variables: {
        id,
      },
    });

  return (
    <>
      <Content>
        <Spaces
          addProps={{
            className: 'mt-6',
            onClick: handleCreateOnClick,
            size: 's',
            text: 'spaces.add.text',
          }}
          deleteFunc={handleDeleteFunc}
          deleteLabel="Voulez-vous vraiment supprimer cet espace ?"
          fields={[
            {
              className: 'flex-1',
              label: 'Nom',
              name: 'name',
            },
            {
              className: 'flex-1',
              label: 'space.list.field.createdAt',
              name: 'createdAt',
              type: 'date',
              typeFormat: 'DD/MM/YY hh:mm',
            },
            {
              className: 'flex-1',
              label: 'space.list.field.places',
              name: 'places',
              valueFunc: value => value.length,
            },
            {
              className: 'flex-1',
              label: 'space.list.field.users',
              name: 'users',
              valueFunc: value => value.length,
            },
            {
              className: 'flex-1',
              label: 'space.list.field.admin',
              name: 'admin',
              valueFunc: value => `${value.firstName} ${value.lastName}`,
            },
          ]}
          itemActions={[]}
          pathname={pathname}
        />
      </Content>
    </>
  );
};

export default requiredAuth(SpacesPage, { admin: true, layout: true });
